import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { StrictMode } from "react";

const themeDark = createTheme({
  typography: {
    fontFamily: [
      '"PlusJakartaSans-ExtraBold"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h5: {
      color: "rgb(102, 178, 255)",
      lineHeight: "1.5",
      letterSpacing: "0.1px",
    },
    body2: {
      color: "#3E5060",
    },
  },
  palette: {
    mode: "dark",
    background: {
      default: "#001D3B",
    },
  },
});

export default function App() {
  return (
    <StrictMode>
      <ThemeProvider theme={themeDark}>
        <CssBaseline></CssBaseline>
        <RouterProvider router={router} />
      </ThemeProvider>
    </StrictMode>
  );
}
