import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
// import bg from './static/bg.jpg'

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary">
      {"Copyright © "}
      <Link underline="none" color="inherit" href="https://finishlinetech.com/">
        东莞市终点线精密机电有限公司
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function StickyFooter() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        // backgroundImage: `url(${bg})`,
        backgroundPosition: "center",
      }}
    >
      <Container component="main" sx={{ mt: 8 }} maxWidth={false}>
        <Typography variant="h1" component="h1" align="center">
          LEBREW
        </Typography>
        <Typography variant="h2" component="h1" align="center">
          BORN FOR COFFEE
        </Typography>
      </Container>

      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: "auto",
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
        }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              mb: 2,
              display: "flex",
              flexWrap: "wrap",
              typography: "body1",
              "& > :not(style) ~ :not(style)": {
                ml: 6,
              },
            }}
          >
            <Link
              href="/privacy"
              underline="none"
              rel="noopener noreferrer"
              target="_blank"
            >
              {"Privacy"}
            </Link>
            <Link
              href="/terms"
              underline="none"
              rel="noopener noreferrer"
              target="_blank"
            >
              {"Terms"}
            </Link>
          </Box>
          <Typography variant="body1">
              <Link
                underline="none"
                color="inherit"
                href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
              >
                粤ICP备2022144368号
              </Link>{" "}
            </Typography>
            <Copyright />
        </Container>
      </Box>
    </Box>
  );
}
