import * as React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

ReactDOM.render(
  <React.Fragment>
    <App></App>
  </React.Fragment>,
  document.getElementById('root'),
);
