import { createBrowserRouter } from "react-router-dom";

import StickyFooter from "./StickyFooter";
import Privacy from "./Privacy";
import Terms from "./Terms";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <StickyFooter />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
  {
    path: "/terms",
    element: <Terms  />,
  },
]);
